1<template>
  <b-card title="Candidate Details:">
    <b-row>
      <!-- User Info: Left col -->
      <b-col class="d-flex justify-content-between flex-column" md="6">
        <table class="w-100">
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Name</span>
            </th>
            <td class="pb-50">
              {{ userData.name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Email</span>
            </th>
            <td class="pb-50">
              {{ userData.email }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Status</span>
            </th>
            <td
              class="pb-50 text-capitalize"
              v-if="$can('Zircly Admin') || $can('Recruiter')"
            >
              <v-select
                v-model="userData.referral_status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="(label) => label.code"
                @input="updateStatus"
                label="label"
                :clearable="false"
                input-id="change-status"
                placeholder="Select Status.."
              />
            </td>
            <td class="pb-50 text-capitalize" v-else>
              {{ userData.referral_status_label }}
            </td>
          </tr>
          <tr v-if="userData.referral_status == 2">
            <th class="pb-50">
              <span class="font-weight-bold">Referral Reject Reason</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.referral_reject_reason }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Position</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.position }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Mobile</span>
            </th>
            <td>
              {{ userData.mobile_number }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Determine</span>
            </th>
            <td>
              {{ userData.determine }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Recommendation</span>
            </th>
            <td>
              {{ userData.recommendation }}
            </td>
          </tr>
        </table>
      </b-col>

      <b-col md="6">
        <b-row>
          <b-col
            v-for="(data, index) in customFields"
            v-bind:key="index"
            md="12"
          >
            <b-row v-if="data.type == 'url'">
              <b-col md="6" class="pb-50 font-weight-bold">
                {{ data.label }}
              </b-col>
              <b-col md="6" class="pb-50">
                <b-link
                  :href="userData.get_customfields_data['field_' + data.id]"
                  class="font-weight-bold d-block text-nowrap"
                  target="_blank"
                >
                <feather-icon icon="ExternalLinkIcon" class="mr-25" />
                  {{ userData.get_customfields_data["field_" + data.id] | str_limit(30)}}
                </b-link>
              </b-col>
            </b-row>
            <b-row v-else>
              <b-col md="6" class="pb-50 font-weight-bold">
                {{ data.label }}
              </b-col>
              <b-col md="6" class="pb-50">
                {{ userData.get_customfields_data["field_" + data.id] }}
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row v-for="history in userData.histories" :key="history.id">
      <b-col md="12">
        <span class="font-weight-bold">Referal date and time : </span>
        {{ history.time }}
      </b-col>
    </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    ToastificationContent,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    customFields: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      statusOptions: [
        { label: "Pending", code: 0 },
        { label: "Converted", code: 1 },
        { label: "Rejected", code: 2 },
      ],
    };
  },
  methods: {
    openWindow(link) {
      window.open(link);
    },
    updateStatus() {
      const self = this;
      self.formData = {};
      self.formData.url =
        "candidate/referral-status-update/" + self.$route.params.id;
      self.formData.params = {
        referral_status: self.userData.referral_status,
      };
      this.$store
        .dispatch("app/store", self.formData)
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Referral Updated",
              icon: "CheckIcon",
              variant: "success",
              text: res.data.message,
            },
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Referral Updating Failed",
              icon: "XCircleIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },
  },
};
</script>

<style>
</style>
